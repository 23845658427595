@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Oswald&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Preahvihear&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: 'Fira Code', monospace;
    /* font-family: 'poopins', monospace; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #11071f;
  overflow-x: hidden;
  color: aliceblue;
}
.last{
  font-size: 0.6rem;
}
.navbar{
  backdrop-filter: blur(10px); 
  z-index: 100;
}


.swiper-slide:nth-child(n) {
  width: 80%;
}

.slide-active-design {
  transform: scale(1.1); /* Agrandir le slide central */
  z-index: 10; /* Mettre le slide central au-dessus des autres */
  transition: transform 0.3s ease; /* Animation douce pour l'agrandissement */
}

/* .swiper-button-next,
.swiper-button-prev {
    padding: 25px;
    background-color: white; 
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;  
    line-height: 10px;
    font-size: 5px;
    text-align: end;
} */






